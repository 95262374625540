import { useEffect, useMemo, useRef, useState } from 'react';
import "../container_v2.css"
import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
} from '@mui/material';

//Date Picker Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const SEARCH_FILTERS = (props) => {
  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 140 }}
          label="Fecha Inicial"
          value={props.fi}
          onChange={(newValue) => props.setfi(newValue)}
        />
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          sx={{ width: 140 }}
          label="Fecha Final"
          value={props.ff}
          onChange={(newValue) => props.setff(newValue)}
        />
      </LocalizationProvider>
      <FormControl required sx={{ width: 70 }} style={{display:props?.join_invoice?"none":"inline-block"}}>
        <InputLabel id="demo-simple-select-required-label">Documento</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={props.tipodoc}
          sx={{ height: '35px' }}
          label="Tipo de documento *"
          onChange={(e) => props.setTipoDoc(e.target.value)}
        >
          <MenuItem value={"FACTURA"}>FACTURA</MenuItem>
          <MenuItem value={"NOTA DE CREDITO"}>NOTA DE CRÉDITO</MenuItem>
          <MenuItem value={"TRASLADO"}>CARTA PORTE</MenuItem>
          <MenuItem value={"PAGO"}>PAGO</MenuItem>
          <MenuItem value={"null"}>TODOS</MenuItem>
        </Select>
      </FormControl>
      
      <FormControl required sx={{ width: 100 }} style={{display:props?.join_invoice?"none":"inline-block"}}>
        <InputLabel id="demo-simple-select-required-label">Estado</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={props.cancel}
          label="Estado *"
          sx={{ height: '35px' }}
          onChange={(e) => props.setCancel(e.target.value)}
        >
          <MenuItem value={true}>CANCELADO</MenuItem>
          <MenuItem value={false}>VIGENTE</MenuItem>
          <MenuItem value={"all"}>TODOS</MenuItem>
        </Select>
      </FormControl>
      <FormControl required sx={{ width: 125 }} style={{display:props?.join_invoice?"none":"inline-block"}}>
        <TextField
          id="outlined-multiline-flexible"
          sx={{ height: '35px' }}
          label="RFC"
          value={props.rfc}
          onChange={(e) => props.setRFC(e.target.value)}
        />
      </FormControl>
      <FormControl required sx={{ width: 125 }} style={{display:props?.join_invoice?"none":"inline-block"}}>
        <TextField
          id="outlined-multiline-flexible"
          label="Socio de Negocios"
          sx={{ height: '35px' }}
          value={props.sdn}
          onChange={(e) => props.setSDN(e.target.value)}

        />
      </FormControl>
      <FormControl required sx={{ width: 100 }}>
        <Button variant="contained"
          sx={{ height: '35px' }}
          onClick={(e) => props.handle_get_invoice()}
        >
          CONSULTAR
        </Button>
      </FormControl>
    </Box>
  );
};

export default SEARCH_FILTERS;
