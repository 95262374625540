import axios from "axios";
import config from "../../config";

const urlspt = `${config?.baseURLSUPPORT}`;

//Consultar Soportes
export const get_factor_importation = async (data) => {
    const url = `https://dkh94m66n1.execute-api.us-east-1.amazonaws.com/prod/factorimportacion`;
    try {
      const response = await axios.get(url, {
        params: {
            filtro: data?.filtro,
            datei: data?.datei,
            datef: data?.datef,
            supplier: data?.supplier,
            whscode: data?.whscode
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };


  export const get_semaphore = async (data) => {
    const url = `https://3uyoh9h5zh.execute-api.us-east-1.amazonaws.com/prod/semaphore`;
    try {
      const response = await axios.get(url, {
        params: {
          filtro: data?.filtro,
      },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };


  

  export const get_semaphore_filter = async () => {
    const url = `https://3uyoh9h5zh.execute-api.us-east-1.amazonaws.com/prod/semaphore`;
    try {
      const response = await axios.get(url, {
          headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "Content-Type": "application/json",
        },
      });
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };
