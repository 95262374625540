import React, { useEffect, useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import {
  Container,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import TableContainer from "../../components_Expenses/Table_Container_Tax";
import Motivo from "../../imgComponents/motivo.png";
import Button from "@mui/material/Button";

import {
  SelectColumnFilter,
  ColumnFilter,
  ColumnFilter2,
} from "../../componentsCFDI/filterMotivo01";
import axios from "axios";
import * as tokenn from "../../services/token";
import * as acesstokenn from "../../services/token";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import LoadingScreen from "react-loading-screen";
import Spinner from "../../imgComponents/S.gif";
import "../Amarely.css"


const Relacionar = ({RFC}) => {

  var date = new Date();

  var dia = `${date.getDate()}`.padStart(2, "0");
  var mes = `${date.getMonth() + 1}`.padStart(2, "0");
  var año = date.getFullYear();
  var añoantes = date.getFullYear();
  var mesantes = Number(mes) - 1;

  if (mesantes == 0) {
    mesantes = `${12}`.padStart(2, "0");
    añoantes=  añoantes-1;
  } else {
    mesantes = `${mesantes}`.padStart(2, "0");
  }

  
  

  const recuperarfi = `${año}-${mes}-${dia}`;
  const recuperarff = `${añoantes}-${mesantes}-${dia}`;

  const dialog = useDialog();
  const [data, setData] = useState([]);
  const [ff, setff] = useState(recuperarfi);
  const [fi, setfi] = useState(recuperarff);
  const [rfc, setRFC] = useState("");
  const [sdn, setSDN] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoding, setIsLoding] = useState(true);




  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoding(true);
    console.log(RFC)

    const uri =
      "https://rfpx9okh43.execute-api.us-east-1.amazonaws.com/od/invoices";

    axios
      .get(uri, {
        params: {
          di: fi,
          df: ff,
          rfc: RFC,
          cancel: false,
        },
        headers: {
          Authorization: sessionStorage.getItem("getIdTokenRefresh"),
          "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const data = response?.data?.filter((item) => item?.serie !== "P").filter((item) => item?.serie !== "CP");

        setData(data);
        setTimeout(() => {
          setIsLoding(false);
          setff("");
          setfi("");
          setRFC("");
          setSDN("");
        }, 1000);
      })
      .catch((err) => {
        console.log("error", err);
        setData([]);

        setTimeout(() => {
          setIsLoding(false);
        }, 1000);
      });
  };

  useEffect(() => {
    const fetchCities = () => {
      
      const uri =
        "https://rfpx9okh43.execute-api.us-east-1.amazonaws.com/od/invoices";

      axios
        .get(uri, {
          params: {
            di: fi,
            df: ff,
            rfc: RFC,
            type: "FACTURA",
            cancel: false,
          },

          headers: {
            Authorization: sessionStorage.getItem("getIdTokenRefresh"),
            "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          const data = response?.data.filter((item) => item?.serie === "RF");

          setData(data);
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    fetchCities();
    setTimeout(() => {
      setIsLoding(false);
    }, 1000);

    if (isLoading == true) {
      console.log("entrandodato: " + isLoading);
    }
  }, [isLoading]);

  const columns = useMemo(
    () => [
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          
          return (
            <di className="iconCenter">
              <img
                src={Motivo}
                className=" cancel"
                onClick={async () => {
                  const result = await Confirm(
                    "Factura seleccionada: " + row?.original?.serie+"-"+row?.original?.folio,
                    "¿ESTA USTED SEGURO DE SELECCIONAR ESTA FACTURA?"
                  );

                  if (result) {
                    // Сonfirmation confirmed
                    var arry={
                      reference1:row?.original?.reference1===undefined?null:row?.original?.reference1,
                      id:row.original.id,
                      folio:row?.original?.folio,
                      serie:row?.original?.serie,
                      cCorreo:row?.original?.cCorreo
                    }
                    dialog.close(arry);
                  } else {
                    // Сonfirmation not confirmed
                  }
                }}
              ></img>
            </di>
          );
        },
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Serie",
        accessor: "serie",
        Filter: SelectColumnFilter,
        filter: "equals",
        id: "seriee",
      },

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Folio",
        accessor: "folio",
      },
      

      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header: "Total",
        accessor: (d) => {
          return d.total
            ? Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total)
            : Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(d.total);
        },
      },
      {
        maxWidth: 70,
        minWidth: 50,
        width: 60,
        Header:  RFC==="CME910516832"?"UUID":"Addenda",
        Filter: SelectColumnFilter,
        filter: "equals",
        accessor: (d)=>{
          return RFC==="CME910516832"?d.uuid_sat:d.reference1!==null?"Si":"No"
        }
      },
    ],
    []
  );

  return (
    <>
     
      <div>
        
            <div>
              {isLoding ? (
                <LoadingScreen
                  className="LoadinScreen"
                  loading={true}
                  bgColor="#f1f1f1"
                  spinnerColor="#9ee5f8"
                  textColor="#676767"
                  logoSrc={Spinner}
                  text="Buscando..."
                />
              ) : (
                <div style={{width:"95%", margin:"0 auto"}}>
                    <div className="div2cM01">
                      <input
                        type="text"
                        className="fi03"
                        onFocus={(e) => {
                          e.currentTarget.type = "date";
                          e.currentTarget.focus();
                        }}
                        placeholder="Fecha inicial"
                        onChange={(event) => setfi(event.target.value)}
                      ></input>

                      <input
                        type="text"
                        className="fi03"
                        onFocus={(e) => {
                          e.currentTarget.type = "date";
                          e.currentTarget.focus();
                        }}
                        placeholder="Fecha Final"
                        onChange={(event) => setff(event.target.value)}
                      ></input>

                      <button
                        className=""
                        onClick={handleSubmit}
                        type="button"
                      >
                        Buscar
                      </button>
                    </div>
                    
                      <TableContainer
                        columns={columns}
                        data={data}
                        exportEx={true}
                        style={{ overflowx: 10 }}
                      />
                </div>
              )}
            </div>
       
      </div>
    </>
  );
};

export default Relacionar;
