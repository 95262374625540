import React, { useState, useEffect, Suspense } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { FileUploader } from "react-drag-drop-files";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Modal_Table from "../../../main_components/modal/modal_Table";
import "../../expense.css";

import {
  get_expense_concept_list_data,
  get_expense_paymentaccounts, 
  create_expense,
  update_expense
} from "../../../services/expenses/expenses";

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const CREATE_UPDATE = (props) => {
  const [Descripcion, setDescripcion] = useState(props?.codDescripcion);
  const [importe, setImporte] = useState(props?.codImporte);
  const [pro_servData, setpro_servData] = useState([]);
  const [accountsList, setaccountsList] = useState([]);
  const [selectaccountsList, setselectaccountsList] =useState(props.codAccount);
  const [ff, setff] = useState(props.codDate);
  const [accounts, setAccounts] = useState(props.codAccount);
  const [account, setAccount] = useState(props.codAccount);
  const [Concepto, setConcepto] = useState(props?.codCocept);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [modalT, setmodalT] = useState(false);
  const dialog = useDialog();


  const handelAccount = (event) => {
    if (event?.target?.value === "9090909090909") {
      setselectaccountsList(null);
    } else {
      setselectaccountsList(event?.target?.value);
    }
  };

  const handelClave_prod_serv = (event) => {
    if (event?.target?.value === "9090909090909") {
    } else {
      const result = pro_servData.filter(
        (item) => item.id_account == event?.target?.value
      );
      setAccounts(result[0]?.id_account);
      setAccount(result[0]?.account);
      setConcepto(result[0]?.concept);
    }
  };

  const handelDescripcion = (event) => {
    setDescripcion(event?.target?.value);
  };

  const handelUnidad = (event) => {
    setImporte(event?.target?.value);
  };
  const handelImportOnBurl = (event) => {
    setImporte(Number(importe).toFixed(2));
  };

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {cb(null, reader.result);};
    reader.onerror = function (error) {cb(error, null);};
  };
  const fileTypes = ["PDF"];

  const handleChange = (file) => {
    fileToBase64(file, (err, result) => {
      if (result) {
        setFile(result);
        setFileName(file);
      }
    });
  };

  const handleprod_concept_list = async () => {
    try {
      var dt = await get_expense_concept_list_data(props.foreing);
      const data = dt.data;
      const dataStatic = {
        id_account: "9090909090909",
        concept: "SELECCIONE UNA OPCIÓN",
        account: "SELECCIONE UNA OPCIÓN",
      };
      data[data.length] = dataStatic;
      setpro_servData(data);
    } catch (error) {
      setpro_servData([]);
    }
  };

  const handleAccountsList = async () => {
    try {
      var dt = await get_expense_paymentaccounts();

      const data = dt.data;
      const dataStatic = {
        id: "9090909090909",
        payment_method: "SELECCIONE UNA OPCIÓN",
      };
      data[data.length] = dataStatic;
      setaccountsList(data);
    } catch (error) {
      setaccountsList([]);
    }
  };


  var data = {
    expense: {
      concept: Concepto,
      id_payment: Number(selectaccountsList),
      account: account,
      description: Descripcion,
      date: ff,
      amount: Number(importe),
      origin: 1,
    },
    file: {
      content: file?.replace("data:application/pdf;base64,", ""),
      type: "pdf",
    },
  };
  console.log(data);

  const handleSubmit = async () => {

    var data = {
      expense: {
        concept: Concepto,
        id_payment: Number(selectaccountsList),
        account: account,
        description: Descripcion,
        date: ff,
        amount: Number(importe),
        origin: props?.origin,
      },
      file: {
        content: file?.replace("data:application/pdf;base64,", ""),
        type: "pdf",
      },
    };

    setmodalT(true);
    try {
        await create_expense(data);
      setmodalT(false);
      dialog.close(true);
    } catch (err) {
      setmodalT(false);
      alert("Error.," + err?.response?.data);
    }

  };




  

  useEffect(() => {
    handleprod_concept_list();
    handleAccountsList();
  }, []);

  return (
    <div>
      <Modal_Table modalT={modalT}></Modal_Table>
      <div>
        <form>
          <Box>
            <TextField
              select
              label="Concepto"
              value={accounts}
              sx={{ m: 1.5, width: "89%" }}
              onChange={handelClave_prod_serv}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {pro_servData.map((option) => (
                <option
                  key={option.id_account}
                  value={option.id_account}
                  selected={option.id_account}
                >
                  {option.concept}
                </option>
              ))}
            </TextField>
          </Box>

          <Box>
            <TextareaAutosize
              label="Descripciónn"
              variant="outlined"
              aria-label="empty textarea"
              value={Descripcion}
              placeholder="Descripción"
              className="TexAreaAutosize"
              onChange={handelDescripcion}
            />
          </Box>

          <div className="generarExpense">
            <div className="expen-6">
              <input
                type="text"
                className="fExpense2"
                onFocus={(e) => {
                  e.currentTarget.type = "date";
                  e.currentTarget.focus();
                }}
                placeholder={ff===""?"FECHA":ff}
                onChange={(event) => setff(event.target.value)}
              ></input>
            </div>
            <div className="expen-7">
              <ValidationTextField
                label="Importe"
                type="number"
                variant="outlined"
                placeholder="Importe"
                sx={{ m: 2, width: "94%" }}
                onChange={handelUnidad}
                onBlur={handelImportOnBurl}
                value={importe}
                InputLabelProps={{ shrink: true }}
              />
            </div>
          </div>

          <Box>
            <TextField
              select
              label="Cuenta de pago"
              value={selectaccountsList}
              sx={{ m: 1.5, width: "89%" }}
              onChange={handelAccount}
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {accountsList.map((option) => (
                <option key={option.id} value={option.id} selected={option.id}>
                  {option.payment_method}
                </option>
              ))}
            </TextField>
          </Box>

          <br></br>

          <div className="">
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              placeholder="123-45-678"
            >
              <div className="drangAndDropExpense">
                <p>Arrastra o selecciona el PDF a enviar</p>
              </div>
            </FileUploader>
            <div className="upload--expense">
              {fileName && <p className="filename">{fileName.name}</p>}
            </div>
            <br />
          </div>

          <Box>
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal"
              onClick={handleSubmit}
            >
              Crear
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default CREATE_UPDATE;
