//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from "@mui/material/Box";
//COMPONENTES
import SubTableContainer from "./SubTableContainer";
import Put_Tax_Concept from "./Put_Tax_Concept";

//ESTILOS
import "../../estilo.css";
import "../../modal.css";
import "../../table.css";

//IMAGENES
import DELETE from "../../../imgComponents/borrar.png";
import Spinner from "../../../imgComponents/S.gif";
import SAVE from "../../../imgComponents/save.png";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
import { delete_concepts_taxes, get_concepts_taxes } from "../../../services/invoices/invoice";

import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../../main_components/methods_v2/export";
import RENDER_ROW_ACTION_MENU_ITEMS_SUBTABLE from "./tools/render_row_action_menuItems_subtable";


const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};



function SubTable_DV2 (props) {

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = async(event) => {
    
    try{
      setIsLoading(true);
      var rf_token = await refresh_token();
      if(rf_token===true){
        setTimeout(async() => {
          try{
            const d = await get_concepts_taxes(props?.id);
            const data = d===null?[]:d.data;
            setData(data);
            props?.setrec ? props?.setrec(false): console.log("");
            setIsLoading(false);

          }catch(err){
            setData([]);
            setIsLoading(false);
          }
        }, 1000);
      }else{}
    }catch(err){
      console.log(err);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [])

  useEffect(() => {
    if(props?.rec){
      handleSubmit();
    }
  }, [props?.rec])
  
  
  
  const columns = useMemo(
    () => [

      // {
      //   header: "Acciones",
      //   id: "Acciones",

      //   Cell: ({ valor, row }) => {
      //     function Redireccion() { 
      //     }
      //     const DeleteTax = async () => {
      //       setOpen(true);
      //       try{
      //         var rf_token=await refresh_token();
      //         if(rf_token===true){
      //           setTimeout(async() => {
      //             try{
      //               const d = await delete_concepts_taxes(sessionStorage.getItem("id_concept_subtable"),row.original.id);
      //               alert("Impuesto eliminado correctamente")
      //               setOpen(false);
      //               window.location="/consult_concept"
      //             }catch(err){
      //               console.log("error", err);
      //               setOpen(false);
      //             }
      //           }, 1000);
      //         }else{}
      //       }catch(err){  
      //         console.log(err);
      //       }

      //       /*
      //       axios
      //         .delete(`https://inxmul26u8.execute-api.us-east-1.amazonaws.com/od/concepts/${sessionStorage.getItem("id_concept_subtable")}/taxes/${row.original.id}?`, {
      //           headers: {
      //             Authorization: sessionStorage.getItem("getIdTokenRefresh"),
      //             "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
      //             "Content-Type": "application/json",
      //           },
      //         })
      //         .then((response) => {
      //           alert("Impuesto eliminado correctamente")
      //           setOpen(false);
      //           window.location="/consult_concept"
      //         })
      //         .catch((err) => {
      //           console.log("error", err);
      //           setOpen(false);
      //         });
      //       */
      //     }

      //     return (
      //       <div>

      //           <img
      //           title="Editar impuesto"
      //           src={SAVE}
      //           onClick={async () => {
                  
      //             const result = await CustomDialog(
      //                 <Put_Tax_Concept 
      //                 base={row.original.base}
      //                 tasa_o_cuotan={row.original.tasa_o_cuota}
      //                 tipo_factorn={row.original.tipo_factor}
      //                 impueston={row.original.impuesto}
      //                 person_typen={row.original.person_type}
      //                 tipon={row.original.tipo}
      //                 id_Impueston={row.original.id}
      //                 id_tax_config_fk={row.original.id_tax_config_fk}
        
      //                 ></Put_Tax_Concept>,
      //               {
      //                 className: "modalTItle",
      //                 title:
      //                   "Editar impuesto del concepto",
      //                 showCloseIcon: true,
      //                 isCanClose:false,
      //               }
      //             );
                
      //         }}
      //           className="centerText, taxes"
      //         ></img>
              
      //         <img
      //           title="Eliminar impuesto"
      //           src={DELETE}
      //           className="centerText, taxes"
      //           onClick={async () => {
      //             const result = await Confirm(
      //               "¿Esta usted seguro de eliminar este impuesto del concepto?",
      //               "Eliminar Impuesto",
      //               "Si",
      //               "No"
      //             );
      //             if (result) {
      //               DeleteTax();
      //             } else {
      //             }
      //           }}
      //         ></img>
      //       </div>
      //     );
      //   },
      // },

      {
        id: "base",
        header: "Base",
        accessorKey: "base",
      },
      {
        id: "impuesto",
        header: "Impuesto",
        accessorKey: "impuesto",
      },
      {
        id: "tipo",
        header: "Tipo",
        accessorKey: "tipo",
      },
      {
        id: "tipo_factor",
        header: "Tipo factor",
        accessorKey: "tipo_factor",
      },

      {
        id: "person_type",
        header: "Tipo persona",
        accessorKey: "person_type",
      },
      
      {
        id: "tasa_o_cuota",
        header: "Tasa/Cuota",
        accessorKey: "tasa_o_cuota",
      },
      {
        id: "importe",
        header: "Importe",
        accessorKey: "importe",
      },

    ],
    []
  );



  return (

    <div>
        <div className="new_table_V2">
        <MaterialReactTable
        columns={columns}
        data={data}
        enableGrouping
        enablePinning
        enableFacetedValues
        enableStickyHeader
        enableStickyFooter
        enableRowPinning
        enableColumnFilterModes
        enableRowActions
        enableRowSelection
        getRowId={(row) => row?.id}
        onRowSelectionChange={setRowSelection}
        enableTooltips={false}
        localization={MRT_Localization_ES}
        state={{ rowSelection, isLoading: isLoading }}
        enableColumnResizing
        enableColumnPinning
        enableColumnOrdering
        enableExpandAll={false}
        initialState={{ showGlobalFilter: true }}
        muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
        muiSkeletonProps={{ animation: 'pulse', height: 28 }}
        rowVirtualizerInstanceRef
        rowVirtualizerOptions={{ overscan: 5 }}
        columnVirtualizerOptions={{ overscan: 2 }}
        muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
        positionToolbarAlertBanner="bottom"
        paginationDisplayMode='pages'
        rowPinningDisplayMode='sticky'
        layoutMode="grid"
        muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", backgroundColor: "", color: "black" } }}
        muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
        muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
        displayColumnDefOptions={{
          'mrt-row-pin': {
            enableHiding: true,
          },
          'mrt-row-actions': {
            enableHiding: true,
          },
          'mrt-row-expand': {
            enableHiding: true,
          },
          'mrt-row-select': {
            enableHiding: true,
          }
        }}
         muiTableBodyRowDragHandleProps={({ table }) => ({
           onDragEnd: () => {
             const { draggingRow, hoveredRow } = table.getState();
             if (hoveredRow && draggingRow) {
               data.splice(
                 hoveredRow.index,
                 0,
                 data.splice(draggingRow.index, 1)[0],
               );
               setData([...data]);
             }
           },
        })}
        muiTableBodyRowProps={
          ({ row, table }) => {
            const { density } = table.getState();
            return {
              sx: {
                height: row.getIsPinned()
                  ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                  }px`
                  : undefined,
              },
            };
          }
        }
        renderRowActionMenuItems={({ row, closeMenu }) => [
              <RENDER_ROW_ACTION_MENU_ITEMS_SUBTABLE
                closeMenu={closeMenu}
                setmodalT={props?.setmodalT}
                row={row}
                handleSubmit={handleSubmit}
                alertas={props?.alertas}
                id={props?.id}
              />
            ]}
         renderToolbarInternalActions={({ table }) => (
             <RENDER_TOOLBAR_INTERNAL_ACTIONS
               table={table} 
               file_name={"Consultar Impuestos del concepto"}
               // update_identifiers={update_identifiers}
             />
            )
            }
      />
                
        </div>
    </div>

  );
};

export default SubTable_DV2;
