import { useEffect, useMemo, useRef, useState } from "react";
import "./buscarcasos.css";
//import '../CreateCase/createcase.css'
import "../comments/comments.css";
import { CustomDialog, useDialog } from "react-st-modal";
import { get_supports } from "../../services/supports/supports";
import AlertResponse from "../../main_components/alerts/alertResponse";
import NotificationSystem from "react-notification-system";
import {
  FormControlLabel,
  Icon,
  TextField,
} from "@material-ui/core";
import { refresh_token } from "../../main_components/tokens/tokenrefresh";
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import RENDER_TOOLBAR_INTERNAL_ACTIONS from "../../main_components/methods_v2/export";
import MODAL_TABLE from "../../main_components/modal/modal_Table";
import RENDER_ROW_ACTION_MENU_ITEMS from "../tools/render_row_action_menuItems";
import { BREADCRUMBS } from "../../main_components/pagination/breadcrumbs";
import CREATE from "../../imgComponents/create.png";
import SupportCaseModal from "../createCase/CreateCase";

export const ViewCases_v2 = () => {
  const [dataoriginal, setDataoriginal] = useState([]);
  const [datafilter, setDatafilter] = useState([]);
  const [opencreate, setOpencreate] = useState(false);
  const [statusticket, setStatusTicket] = useState("OPEN");
  const [statusticket2, setStatusTicket2] = useState("PROCESS");
  const notificationSystemRef = useRef();
  const [filtersColumsView, setfiltersColumsView] = useState(true);


  const [modalT, setmodalT]=useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [reconsult, setreconsult]=useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const dialog = useDialog();
  const [total_rows, settotal_rows] = useState("");

  const handleCloseCreate = () => {
    setOpencreate(false);
  };

  const handleFilterData = (event) => {
    if (event.target.value === "CLOSE") {
      setStatusTicket(event.target.value);
      setStatusTicket2(event.target.value);
    } else {
      setStatusTicket(event.target.value);
      setStatusTicket2("PROCESS");
    }
  };

  function alertas(msj, status) {
    if (status === true) {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={true}></AlertResponse>,
        level: "success",
        position: "br",
        autoDismiss: 20,
      });
      handleLoad();
    } else {
      notificationSystemRef.current.addNotification({
        message: <AlertResponse msj={msj} view={false}></AlertResponse>,
        level: "error",
        position: "br",
        autoDismiss: 20,
      });
    }
  }

  const handleLoad = async () => {
    try {
      const refresh = await refresh_token();
      if (refresh) {
        setmodalT(true);
        setDataoriginal([]);
        setIsLoading(true);

        try {
          let data = await get_supports();

          console.log(data?.data);

          setDatafilter(
            data?.data?.filter(
              (val) =>
                val.status === statusticket || val.status === statusticket2
            )
          );
          setIsLoading(false);
          setmodalT(false);
          setDataoriginal(data.data);
        } catch (err) {
          setIsLoading(false);
          setDataoriginal([]);
          setmodalT(false);
        }
      }
    } catch (error) {
      console.log("Error al refrescar", error);
    }
  };


  
  const update_identifiers =
  [
    { date: 'string' },
    { subject: 'string' },
    { severity: 'string' },
    { folio: 'string' },
    { ticket: 'string '},
    { type: 'string' },
    { status: 'string' },
    { company_code: 'string' },
    { user_email: 'string' },
    { submodule: 'string' },
    { module: 'string '}
  ];

  const columns = useMemo(
    () => [
       {
         header: "Fecha",
         id: "date",
         accessorKey: "date",
         Cell: ({ cell }) => {
          const [datePart, timePart] = cell.getValue()?.split(".")[0].split(" ");
          return(
           <>
            {datePart}
              <br />
            {timePart}
           </>
          )
        }
       },
      {
        header: "Asunto",
        accessorKey: "subject",
        id: "subject",
      },
      {
        header: "Severidad",
        accessorKey: "severity",
        id: "severity",
      },
      {
        header: "Folio",
        accessorKey: "folio",
        id: "folio",
      },
      {
        header: "UID",
        accessorKey: "ticket",
        id: "ticket",
      },
      {
        header: "Tipo",
        accessorKey: "type",
        id: "type",
      },
       {
         header: "Estado",
         accessorKey: "status",
         accessorFn: (d)=>{return d.status==='OPEN'?'ABIERTO':d.status==='PROCESS'?'EN REVISIÓN':'CLOSED' },
         id: "status",
       },
       {
         header: "Compañía",
         accessorKey: "company_code",
         id: "company_code",
       },
       {
        header: "Email",
        accessorKey: "user_email",
        id: "user_email",
       },
       {
         header: "Sub-Módulo",
         accessorKey: "submodule",
         id: "submodule",
       },
       {
        header: "Módulo",
        accessorKey: "module",
        id: "module",
       },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      refreshing,
    ]
  );

  useEffect(() => {
    handleLoad();
  }, []);

  useEffect(() => {
    setmodalT(true);
    if (statusticket === "TODOS") {
      setDatafilter(dataoriginal);
    } else {
      setDatafilter(
        dataoriginal.filter(
          (val) => val.status === statusticket || val.status === statusticket2
        )
      );
    }
    setmodalT(false);
  }, [statusticket]);

  const handleStateChange = (newState) => {
    const filteredRowsCount = newState.filteredRowModel
    settotal_rows(filteredRowsCount);
  };


  return (
    <div className="TableConsultCases">

    <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
    <MODAL_TABLE
          open={true}
          message={""}
          modalGeneral={false}
          modalT={modalT}
    ></MODAL_TABLE>
      <BREADCRUMBS niveles={
                [
                  {label: "CONSULTAR HISTORIAL DE CASOS", path: null},
                ]
              }
          old_version={true}
          modul="Consultar historial de casos v1"
          path="/help_center"  
          cards_dashboard={true}
          total_rows={total_rows !== "" ? total_rows : 0}
          filter_row={total_rows !== "" ? total_rows?.getFilteredRowModel()?.rows?.length : 0}
          visibility_row={total_rows !== "" ? total_rows?.getPaginationRowModel()?.rows?.length : 0}
          total={datafilter?.length}       
      ></BREADCRUMBS>
      <div >
        <div className="new_table_v2">
        <MaterialReactTable
              columns={columns}
              data={datafilter}
              enableGrouping
              enablePinning
              enableFacetedValues
              enableStickyHeader
              onStateChange={handleStateChange}
              enableStickyFooter
              enableRowPinning
              enableColumnFilterModes
              enableRowActions
              enableRowSelection
              getRowId={(row) => row?.id}
              onRowSelectionChange={setRowSelection}
              enableTooltips={false}
              localization={MRT_Localization_ES}
              state={{ rowSelection, isLoading: isLoading }}
              enableColumnResizing
              enableColumnPinning
              enableColumnOrdering
              enableExpandAll={false}
              initialState={{ showGlobalFilter: true }}
              muiCircularProgressProps={{ color: 'secondary', thickness: 5, size: 55 }}
              muiSkeletonProps={{ animation: 'pulse', height: 28 }}
              rowVirtualizerInstanceRef
              rowVirtualizerOptions={{ overscan: 5 }}
              columnVirtualizerOptions={{ overscan: 2 }}
              muiTableContainerProps={{ sx: { maxHeight: '69vh', '@media (max-height: 810px)': { maxHeight: '65vh' } } }}
              positionToolbarAlertBanner="bottom"
              paginationDisplayMode='pages'
              rowPinningDisplayMode='sticky'
              layoutMode="grid"
              muiTableHeadCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important", backgroundColor: "", color: "black" } }}
              muiTableBodyCellProps={{ sx: { flex: '0 0 auto', whiteSpace: "normal !important" } }}
              muiPaginationProps={{ color: 'primary', shape: 'rounded', variant: 'variant', }}
              displayColumnDefOptions={{
                'mrt-row-pin': {
                  Header: () => (
                    <div>
                      <img
                      title="Crear Ticket"
                      src={CREATE}
                      className=""
                      onClick={async () => {

                        const result = await CustomDialog(
                          <div>
                            <SupportCaseModal
                              isOpen={opencreate}
                              onClose={handleCloseCreate}
                              alertas={alertas}
                              
                            />
                          </div>,
                          {
                            className: "custom-modal",
                            title:"Crear nuevo ticket",
                            showCloseIcon: true,
                            isCanClose: false,
                          }
                        );
                      }}
                    ></img>
                    </div>
                  ),
                  enableHiding: true,
                },
                'mrt-row-actions': {
                  enableHiding: true,
                },
                'mrt-row-expand': {
                  enableHiding: true,
                },
                'mrt-row-select': {
                  enableHiding: true,
                }
              }}
              muiTableBodyRowDragHandleProps={({ table }) => ({
                onDragEnd: () => {
                  const { draggingRow, hoveredRow } = table.getState();
                  if (hoveredRow && draggingRow) {
                    datafilter.splice(
                      hoveredRow.index,
                      0,
                      datafilter.splice(draggingRow.index, 1)[0],
                    );
                    setDatafilter([...datafilter]);
                  }
                },
              })}
              muiTableBodyRowProps={
                ({ row, table }) => {
                  settotal_rows(table)
                  const { density } = table.getState();
                  return {
                    sx: {
                      height: row.getIsPinned()
                        ? `${density === 'compact' ? 30 : density === 'comfortable' ? 35 : 69
                        }px`
                        : undefined,
                    },
                  };
                }
              }
              renderTopToolbarCustomActions={({ }) => (
                <div className="statusticketselec">
                <TextField
                  fullWidth
                  className=""
                  variant="outlined"
                  select
                  label="Estado del ticket"
                  defaultValue={""}
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ native: true }}
                  onChange={(event) => handleFilterData(event)}
                >
                  <option key={1} value={"OPEN"}>
                    {"ABIERTO"}
                  </option>
                  <option key={2} value={"CLOSE"}>
                    {"CERRADO"}
                  </option>
                  <option key={3} value={"TODOS"}>
                    {"TODOS"}
                  </option>
                </TextField>
                </div>
              
              )}
              renderRowActionMenuItems={({ row, closeMenu }) => [
                 <RENDER_ROW_ACTION_MENU_ITEMS
                   closeMenu={closeMenu}
                   setmodalT={setmodalT}
                   setmenssage={""}
                   setmodalGeneral={false}
                   row={row}
                   handlesubmit={handleLoad}
                   setreconsult={setreconsult}
                   refresh_token={refresh_token}
                   alertas={alertas}
                 />
               ]}
              renderToolbarInternalActions={({ table }) => (
                 <RENDER_TOOLBAR_INTERNAL_ACTIONS
                   table={table}
                   file_name={"Historial de casos"}
                   update_identifiers={update_identifiers}
                   create_modul={"help_center"}
                   isOpen={opencreate}
                   onClose={handleCloseCreate}
                   alertas={alertas}
                   settotal_rows={settotal_rows}
                 />
               )
              }
            ></MaterialReactTable>
        </div>
      </div>
    </div>
  );
};
