import { SignIn } from "aws-amplify-react/lib/Auth";
import { AuthState } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import LoadingScreen from "react-loading-screen";
import Spinner from "./S.gif";
import fondo from "../img/fondo.jpg"
import ht from "../img/logo.png";
import ReactPlayer from 'react-player/lazy';
import { CustomDialog} from "react-st-modal";

// import ReactPlayer from 'react-player/lazy';

export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);

    this.state = {
      Username: "",
      Password: "",
      IsLoading:false,
    };

    this.signIn = this.signIn.bind(this);
    this.handleFormSubmission = this.handleFormSubmission.bind(this);
    this.handleFormSubmission2 = this.handleFormSubmission2.bind(this);

  }

  handleFormSubmission(evt) {
    evt?.preventDefault();
    if (evt.keyCode === 13) {
      // aquí va la lógica que queremos ejecutar
      console.log("hola")
      this.setState({ IsLoading: true });
      this.signIn();
    }
  }


  handleFormSubmission2(evt) {
    evt?.preventDefault();
    this?.signIn();
    this?.setState({ IsLoading: true });
  }
  mostrarContrasena() {
    var tipo = document.getElementById("password-fieldSingIn");
    if (tipo.type === "password") {
      tipo.type = "text";
    } else {
      tipo.type = "password";
    }
  }

  async signIn() {
    const username = this.state.Username;
    const password = this.state.Password;
    try {
      await Auth.signIn(username, password);
      await this.props.SetAuthState(AuthState.SignedIn);
    } catch (err) {
      this.setState({ IsLoading: false });
    
      if (err.code === "UserNotConfirmedException") {
        this.setState({ error: "Login failed." });
        console.log("1");
      } else if (err.code === "NotAuthorizedException") {
        this.setState({ error: "Login failed." });
        console.log("2");
        this.setState({ IsLoading: false });
        alert("El usuario o la contraseña no coinciden")

      } else if (err.code === "UserNotFoundException") {
        this.setState({ error: "Login failed." });
        console.log("3");

      } else {
        this.setState({ error: "An error has occurred." });
        console.error(err);

      }
    }
  }



  

  showComponent(theme) {
    return (
      <>
       <div className='container_login'>
       {this.state.IsLoading ? (
          <LoadingScreen
            className="LoadinScreen"
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc={Spinner}
            text={"Ingresando..."}
          />
        ) : (
          <div className='grid-login'>
            <div className='formsesion'>
              <div className='logoempresa'>
                  <img alt="" src={ht}></img>
              </div>
              <div className='camplogin'>
              < div className="div-lg1">
                  <p className='bienvenido'>Bienvenido de nuevo</p>
                  <p className='text-login'>Inicia sesión para continuar</p>
                </div>
                <div className='grid-form-login'>
                <div className='form-user'>
                  <label className='label-usuario'>Usuario</label>
                  <input
                  className="form-control text-center"
                  type="text"
                  placeholder="Nombre de usuario"
                  onChange={(e) =>
                    this.setState({ Username: e.target.value })
                  }
                ></input>
              </div> 
              <div className='form-pass'>
                <div className='pass1grid'>
                <label className='label-contraseña'>Contraseña</label>
                <input
                  id="password-fieldSingIn"
                  className="text-center form-control"
                  type="password"
                  placeholder="Contraseña"
                  onKeyUp={this.handleFormSubmission}

                  onChange={(e) =>
                    this.setState({ Password: e.target.value })
                  }
                ></input>
                <span
                  className="material-symbols-outlined  field-icon"
                  onClick={this.mostrarContrasena}
                  
                >
                  visibility
                </span>
            </div>
          </div>
          <div className=''>
            <button className='boton-login' onClick={this.handleFormSubmission2}>
              Iniciar Sesión
            </button> 
            
            <button className='boton-registrarse' 
              onClick={()=>this.props.SetAuthState(AuthState.SignUp)}>
              {/* this.props.SetAuthState(AuthState.SignUp)}> */}
              Registrarse
            </button>        
            <a className='forgot-pass'
              onClick={()=>this.props.SetAuthState(AuthState.ForgotPassword)}
            >Olvidé mi Contraseña</a>
          </div>
          <div className="FlexContainer">
              {/* <div className="">
                <a
                  className="SignInList"
                  onClick={async () => {      
                    const result = await CustomDialog(
                      <ReactPlayer
                      className='react-player modalVideoReactplay'
                      url=""
                      width='100%'
                      height='100%'
                      controls = {true}          
                      />,
                      {
                        className: "modalTItle",
                        title:
                          "",
                        showCloseIcon: true,
                        isCanClose:false,
                      }
                    );  
                }}
                  href="#0"
                >
                  ¿Cómo recupero mi contraseña?
                  <i className='far fa-play-circle' />
                </a>
              </div> */}

              <div className="como-registro">
                <a
                  className="SignInList"
                  onClick={async () => {
                  
                    const result = await CustomDialog(
                      
                      <ReactPlayer
                      className='react-player modalVideoReactplay'
                      url="https://hisoporte.s3.amazonaws.com/videos/Registrar+usuario.mp4"
                      width='100%'
                      height='100%'
                      controls = {true}          
                      />,
                      {
                        className: "modalTItle",
                        title:"",
                        showCloseIcon: true,
                        isCanClose:false,
                      }
                    );
                  
                }}
                  href="#0"
                >
                  ¿Cómo me registro ?
                  <i className='far fa-play-circle' />

                </a>

              </div>

            
              </div>
          </div>
        </div>
        
        </div>
        <div className='imglogin'>
                <img src={fondo}></img>
        </div>
        </div>
        )
       }
       </div>


{/* 
      <div className="colorfondo">

          {this.state.IsLoading ? (
          <LoadingScreen
            className="LoadinScreen"
            loading={true}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            logoSrc={Spinner}
            text={"Ingresando..."}
          />
        ) : (
          <div className="parent clearfix">
          <div className="bg-illustration"></div>

          <div className="login"                  >
            <div className="container">
              <br></br>
              <h2 className="titleOD2"> Iniciar Sesión</h2>

              <img className="avatar2" src={BG} alt="logo"/>
              <br></br>
              <br></br>
              <div className="form-group">
                <input
                  className="form-control text-center"
                  type="text"
                  placeholder="Nombre de usuario"
                  onChange={(e) =>
                    this.setState({ Username: e.target.value })
                  }
                ></input>
              </div>

              <div className="form-group">
                <input
                  id="password-fieldSingIn"
                  className="text-center form-control"
                  type="password"
                  placeholder="Contraseña"
                  onKeyUp={this.handleFormSubmission}

                  onChange={(e) =>
                    this.setState({ Password: e.target.value })
                  }
                ></input>
                <span
                  className="material-symbols-outlined  field-icon "
                  onClick={this.mostrarContrasena}
                  
                >
                  visibility
                </span>
              </div>

              <div className="form-group">
                <a
                  className="form-control btn btn-primary"
                  onClick={this.handleFormSubmission2}
                  href="#0"
                >
              
                  Iniciar Sesión
                </a>
              </div>
              <div className="FlexContainer">
                <div className="">
                  <a
                  className="SignInList"
                  onClick={() =>
                      this.props.SetAuthState(AuthState.ForgotPassword)
                    }
                    href="#0"
                  >
                    Olvidé mi contraseña
                  </a>
                </div>
                <div className="">
                  <a
                  className="SignInList"
                  onClick={() => super.changeState("signUp")}
                    href="#0"
                  >
                    Registrarse
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div> */}
    </>
  );
}
}

export default CustomSignIn;