//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";

import NotificationSystem from 'react-notification-system';
//COMPONENTES
import TableContainer from "../TableContainerBusnes";

import AlertResponse from "../../main_components/alerts/alertResponse";
import { get_catalogs } from "../../services/catalogs/catalogs";
import { post_catalogs } from "../../services/catalogs/catalogs";
import { put_catalogs } from "../../services/catalogs/catalogs";
import { delete_catalogs } from "../../services/catalogs/catalogs";
import Methods from "./methods/methods";
import Modal_Table from "../../main_components/modal/modal_Table";
//ESTILOS
import "./general_catalogs.css";
import { upperCase } from "lodash";

import CREATE from "../../imgComponents/create.png";
import DELETE from "../../imgComponents/borrar.png";
import SAVE from "../../imgComponents/save.png";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});

const TableCatalogs = (props) => {
  
  const notificationSystemRef = useRef();
  const [value, setValue] = React.useState("");
  const [valuetwo, setValuetwo] = React.useState("");
  const [valuethree, setValuethree] = React.useState("");
  const [id_value_data, setid_value_data] = React.useState("");
  const [data, setData] = React.useState([]);

  const [modalT, setmodalT] = useState(false);
  const [menssage, setmenssage] = useState("");
  const [modalGeneral, setmodalGeneral] = useState(false);
  const [open, setopen]=useState(false);


  const reconsultar=(n)=>{
    if(n==true){
      setmodalT(true);  
    }else{
      setmodalT(false);
    } 
  }

  
function alerts(msj,status){

  if(status==true){
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={true} ing={true}></AlertResponse>,
      level: 'success',
      position: 'br', 
      autoDismiss: 10, 
    });  
  }else{
    notificationSystemRef.current.addNotification({
      message:<AlertResponse msj={msj} view={false} ing={true}></AlertResponse>,
      level: 'error',
      position: 'br', 
      autoDismiss: 60, 
    });
  }  
}

  useEffect(() => {
    handledata();
  }, []);

  const handledata = async () => {
    setData([]);
    try {
      setopen(true);
      setmodalT(true);  
      var res = await get_catalogs(props.tablename);
      res.sort((a, b) => b.id - a.id);
      setData(res);
      setopen(false);
      setmodalT(false);
    } catch (err) {
      console.log(JSON?.stringify(err.response));
      setData([]);
      setopen(false);
      setmodalT(false);
    }
  };

  
  const handle_delete_data = async (id_value) => {
    setmodalT(true);
    try {
      var res = await delete_catalogs(props.tablename, id_value);
      alerts("Successfully Removed", true);
      handlereconsult(true);
    } catch (err) {
      console.log(JSON?.stringify(err.response));
      alerts("Error Deleting", false);
      setmodalT(false);
    }
  };

  const handlereconsult=(valid)=>{
    if(valid===true){
      setData([]);
      handledata();
    }else{
      
    }
  }

  const columns = useMemo(
    () => [
      {
        Header:()=>(
          <div>
            {
              props.tablename==="EXR"?<>Actions</>:<img
              src={CREATE}
              title="Create"
              onClick={async () => {
               const result = await CustomDialog(
                 <Methods  response={props?.response} response2={props?.response2} response3={props?.response3} value={value} valuetwo={valuetwo} valuethree={valuethree} tablename={props?.tablename} columdata={props?.columdata} visibilitydate={props?.visibilitydate} method={"post"} reconsultar={reconsultar} alerts={alerts}></Methods>,
                 {
                   className: "",
                   title: "CREATE",
                   showCloseIcon: true,
                   isCanClose: false,
                 }
               );
               handlereconsult(result);
             }}
             
            ></img>  
            }
           
          </div>
        ),
        id: "expander",
        Cell: ({ row }) => {
          return (
            <div>
              
              <img
                src={SAVE}
                title="Update"
                onClick={async () => {
                 const result = await CustomDialog(
                   <Methods  id_data={row.original.id} response={props?.response} response2={props?.response2} response3={props?.response3} value={row.original[`${props.response}`]} valuetwo={row.original[`${props.response2}`]} valuethree={row.original[`${props.response3}`]} tablename={props?.tablename} columdata={props?.columdata} visibilitydate={props?.visibilitydate} method={"put"} reconsultar={reconsultar} alerts={alerts}></Methods>,
                   {
                     className: "",
                     title: "UPDATE",
                     showCloseIcon: true,
                     isCanClose: false,
                   }
                 );
                 handlereconsult(result);
               }}
             
              ></img>
             
             {
                props.tablename==="EXR"?<></>:
                <img
                src={DELETE}
                title="Delete"
                onClick={
                  async () => {
                    const result = await Confirm(
                      "Are you sure to delete the attribute?",
                      "DELETE",
                      "YES",
                      "No"
                    );
                    if (result) {
                      handle_delete_data(row.original.id);
                    } else {
                    }
                  }
                }
              ></img>
              }
              
            </div>
          );
        },
      },

      {
        Header: `${upperCase(props.response)}`,
        accessor: `${props.response}`,
      },
    ],
    [data]
  );

  const columns2 = useMemo(
    () => [
      {
        Header:()=>(
          <div>
            <img
                src={CREATE}
                title="Create"
                onClick={async () => {
                 const result = await CustomDialog(
                   <Methods  response={props?.response} response2={props?.response2} response3={props?.response3} value={value} valuetwo={valuetwo} valuethree={valuethree} tablename={props?.tablename} columdata={props?.columdata} visibilitydate={props?.visibilitydate} method={"post"} alerts={alerts} reconsultar={reconsultar}></Methods>,
                   {
                     className: "",
                     title: "CREATE",
                     showCloseIcon: true,
                     isCanClose: false,
                   }
                 );
                 handlereconsult(result);
               }}
             
              ></img>
          </div>
        ),
        id: "expander",

        Cell: ({ row }) => {
    
          return (
            <div>
              <img
                src={SAVE}
                title="Update"
                onClick={async () => {
                 const result = await CustomDialog(
                   <Methods  id_data={row.original.id} response={props?.response} response2={props?.response2} response3={props?.response3} value={row.original[`${props.response}`]} valuetwo={row.original[`${props.response2}`]} valuethree={row.original[`${props.response3}`]} tablename={props?.tablename} columdata={props?.columdata} visibilitydate={props?.visibilitydate} method={"put"} alerts={alerts} reconsultar={reconsultar}></Methods>,
                   {
                     className: "",
                     title: "UPDATE",
                     showCloseIcon: true,
                     isCanClose: false,
                   }
                 );
                 handlereconsult(result);
               }}
             
              ></img>
              <img
                src={DELETE}
                title="Delete"
                onClick={
                  async () => {
                    const result = await Confirm(
                      "Are you sure to delete the attribute?",
                      "DELETE",
                      "YES",
                      "No"
                    );
                    if (result) {
                      handle_delete_data(row.original.id);
                    } else {
                    }
                  }
                }
              ></img>
            </div>
          );
        },
      },

      {
        Header: `${upperCase(props.response)}`,
        accessor: (d)=>{
          console.log(props.tablename)
          return props.tablename==="Closing_Chances"?`${d.closing_chances}%`:`${d.closing_chances}`
        }
      },
      {
        Header: `${upperCase(props.response2)}`,
        accessor: `${props.response2}`
      },
    ],
    [data]
  );

  const columns3 = useMemo(
    () => [
      {
        
        Header:()=>(
          <div>
            <img
                src={CREATE}
                tile="Create"
                onClick={async () => {
                 const result = await CustomDialog(
                   <Methods  response={props?.response} response2={props?.response2} response3={props?.response3} value={value} valuetwo={valuetwo} valuethree={valuethree} tablename={props?.tablename} columdata={props?.columdata} visibilitydate={props?.visibilitydate} method={"post"} alerts={alerts} reconsultar={reconsultar}></Methods>,
                   {
                     className: "",
                     title: "CREATE",
                     showCloseIcon: true,
                     isCanClose: false,
                   }
                 );
                 handlereconsult(result);
               }}
             
              ></img>
          </div>
        ),
        id: "expander",
        Cell: ({ row }) => {
          return (
            <div>
              <img
                src={SAVE}
                onClick={async () => {
                 const result = await CustomDialog(
                   <Methods  id_data={row.original.id} response={props?.response} response2={props?.response2} response3={props?.response3} value={row.original[`${props.response}`]} valuetwo={row.original[`${props.response2}`]} valuethree={row.original[`${props.response3}`]} tablename={props?.tablename} columdata={props?.columdata} visibilitydate={props?.visibilitydate} method={"put"} alerts={alerts} reconsultar={reconsultar}></Methods>,
                   {
                     className: "",
                     title: "UPDATE",
                     showCloseIcon: true,
                     isCanClose: false,
                   }
                 );
                 handlereconsult(result);
               }}
             
              ></img>
              <img
                src={DELETE}
                title="Delete"
                onClick={
                  async () => {
                    const result = await Confirm(
                      "Are you sure to delete the attribute?",
                      "DELETE",
                      "YES",
                      "No"
                    );
                    if (result) {
                      handle_delete_data(row.original.id);
                    } else {
                    }
                  }
                }
              ></img>
            </div>
          );
        },
      },

      {
        Header: `${upperCase(props.response)}`,
        accessor: `${props.response}`,
      },
      {
        Header: `${upperCase(props.response2)}`,
        accessor: `${props.response2}`,
      },

      {
        Header: `${upperCase(props.response3)}`,
        accessor: `${props.response3}`,
      },
    ],
    [data]
  );

  return (
    <div className="table-catalogs">
     <Modal_Table
    open={true}
    message={menssage}
    modalGeneral={modalGeneral}
    modalT={modalT}
  ></Modal_Table>
   <NotificationSystem  ref={notificationSystemRef} ></NotificationSystem>
 
      <TableContainer
        exportEx={true}
        columns={
          props.columdata === 3
            ? columns3
            : props.columdata === 2
            ? columns2
            : columns
        }
        data={data}
        anualSale={true}
        Gmodal={open}
        style={{ overflowx: 10 }}
        renderRowSubComponent={(row) => {
          return <div style={{ padding: "20px" }}></div>;
        }}
      />
    </div>
  );
};

export default TableCatalogs;
