import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { alpha, styled } from "@mui/material/styles";
import Relacionar from "./Relacionar";
import React, { useState, useEffect, useMemo } from "react";
import { CustomDialog, useDialog, Confirm } from "react-st-modal";

import { useDropzone } from "react-dropzone";
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import Button from "@mui/material/Button";
import TableContainer from "../../components_Expenses/Table_Container_Tax";
import Motivo from "../../imgComponents/borrar.png";

export default function AddDocs({vtable}) {
  const [status, setStatus] = useState(false);
  const [type, setType] = useState("success");
  const [cueropoalert, setcueropoalert] = useState("success");
  const [title, setTitle] = useState("This is a alert");
  const [file, setFile] = useState(null);
  const [docs, setDocs] = useState([]);

  const [fileName, setFileName] = useState(null);

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };
  const fileTypes = ["PDF"];

  const handleChange = (file) => {
    fileToBase64(file, (err, result) => {
      if (result) {
        setFile(result);
        setFileName(file);

        setDocs([
          ...docs,
          {
            name: file.name,
            content: result?.replace("data:application/pdf;base64,", ""),
            type: "pdf",
          },
        ]);
      }
    });
  };
  useEffect(() => {
    setDocs(docs);
    sessionStorage.setItem("doscTable",JSON.stringify(docs))
  }, [docs]);

  useEffect(() => {
    if(vtable===true){
      setDocs([]);
    }else{
      
    }
  }, [vtable]);

  console.log(docs);

  function handleSubmit(event) {
    event?.preventDefault();

    var dato = {
      fileName: fileName?.name,
      fileEncoded: file?.replace("data:application/pdf;base64,", ""),
    };

    let axiosConfig = {
      headers: {
        Authorization: sessionStorage.getItem("getIdTokenRefresh"),
        "access-token": sessionStorage.getItem("getAccesTokenRefresh"),
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        "https://m10hvtuy76.execute-api.us-east-1.amazonaws.com/Inicial/purchaseorder",
        dato,
        axiosConfig
      )

      .then((res) => {
        // console.log("RESPONSE RECEIVED: ", res);
      })

      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
      });
  }

  function limpiarycerra() {
    setStatus(false);
    window.location = "/marelli";
  }


  const handleDelete = (r) =>{
    console.log(r);

    var posicion=docs.filter((e)=> e.name!==r);
    setDocs(posicion);
  }

  const columns = useMemo(
    () => [
      {
        Header: "Acciones",
        id: "Acciones",

        Cell: ({ valor, row }) => {
          
          return (
            <di className="iconCenter">
              <img
                src={Motivo}
                className="deletedoc"
                onClick={async () => {
                  const result = await Confirm(
                    "¿Esta usted seguro de eliminar este archivo?"
                  );

                  if (result) {
                    handleDelete(row.original.name);
                  } else {

                  }
                }}
              ></img>
            </di>
          );
        },
      },

      {
        Header: "Archivo",
        accessor: "name",
        id: "name",
      },
      {
        Header: "Tipo",
        accessor: "type",
        id: "type",
      },
     
     
    ],
    [docs]
  );


  return (
    <div className="App">
      <div className="">
        <div className="containerDDP">
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            placeholder="123-45-678"
          >
            <div className="drangAndDrop2">
              <input className="drangAndDropInput" value="Arrastra o selecciona el documento pdf" disabled={true}></input>
            </div>
          </FileUploader>
         
          <br />
        </div>
      </div>
      <div style={{width:"95%", margin:"0 auto"}}>
      <TableContainer
        columns={columns}
        data={docs}
        exportEx={true}
        style={{ overflowx: 10 }}
      />
      </div>
      
    </div>
  );
}
