import { useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import { useDialog } from "react-st-modal";
import { refresh_token } from "../../../main_components/tokens/tokenrefresh";
// import { get_data_businesspartners, get_businesspartners_rfc } from "../../../services/businesspartners/businesspartners";

function DataExt(dponit) {
  console.log(dponit);


  const [comentary, setcomentary] = useState(dponit.system_commentary);




  function comentarios(com) {
    const newText = com.replace(/\|\|/g, "\n");
    const newText2 = newText.replace(/\|/g, "\n");
    const newText3 = newText2.replace(
      "Importación manual.",
      "Importación manual.\n"
    );
    const parrafos = newText3.split("\n\n");
    parrafos.reverse();
    const invertedtext = parrafos.join("\n\n");
    setcomentary(invertedtext?.trim());
  }

  const columns = useMemo(
    () => [
      {
        id: "expander",
        Header: "Acciones",
        Cell: ({ row }) => {
          function guardardato() {
            sessionStorage.setItem("cabinetsBs", row.original.id);
          }

          return (
            <a {...row.getToggleRowExpandedProps()}>
              {row?.isExpanded ? (
                <span>🔼</span>
              ) : (
                <span onClick={guardardato}>🔽</span>
              )}
            </a>
          );
        },
      },
      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Punto de venta",
        accessor: "name",
      },
      {
        Header: "RFC",
        accessor: "rfc",
      },
      {
        Header: "Ruta",
        accessor: "route",
      },
      {
        Header: "Fecha de registro",
        accessor: "date_register",
      },
    ],
    []
  );

  return (
    <div>
      <div className="formulariopointofsale">
        <div>
        

          <div className="contenirdivH">
            <h1 className="h1conge">Historial de actualizaciones: </h1>

            <textarea
              className="textareapuntosventa"
              value={comentary}
            ></textarea>
          </div>
        </div>

        <br></br>
        <br></br>
        <Box>
          {/* <Button
            style={{ "background-color": "red" }}
            variant="contained"
            sx={{ m: 1.5, width: "20ch" }}
            onClick={() => {
              dialog.close();
            }}
          >
            cerrar
          </Button> */}
        </Box>
      </div>
    </div>
  );
}
export default DataExt;
