import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import { alpha, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Spinner from "../../../../imgComponents/S.gif";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import EFECTO from "../../../../dataComponets/efect.json"
import { CustomDialog, useDialog, Confirm } from "react-st-modal";
import { create_expense_detail,update_expense_detail,get_accounting_accounts } from "../../../../services/expenses/expenses";
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
const style2 = {position: 'absolute' ,top: '50%',left: '50%',transform: 'translate(-50%, -50%)',width: '100%',height: '100%', bgcolor: 'background.paper',border: '2px solid #000',boxShadow: 24,p: 4,};

const ValidationTextField = styled(TextField)({
  "& input:valid + fieldset": { borderColor: "green", borderWidth: 2 },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});


const CREATE_UPDATE = (props) => {
  const dialog = useDialog();
  const [open, setOpen] = React.useState(false);
  const [Concepto, setConcepto] = useState("");
  const [importe, setImporte] = React.useState(props.codamount);
  const [pro_servData, setpro_servData] = React.useState([]);
  const [efecto, setefecto] = React.useState(props.codeffect);
  const [id_concept, setid_concept] = useState(props?.codidConcept);
  const [concept_data, setconcept_data] = useState(props?.codconcept);
  const [concept_code, setconcept_code] = useState(props?.codaccounts);
  
  
  const handleOnSearch = (string, results) => {};
  
  const handleOnHover = (result) => {};
  const handleOnSelect = (item) => {
    setConcepto(item);
    setid_concept(item.id);
    setconcept_data(item.name);
    setconcept_code(item.code);
  };

  const handleOnFocus = () => {
    console.log("Focused");
  };
 
  const handleOnClear = () => {
    console.log("Cleared");
  };

  const handelImporte = (event) => {
    setImporte(event?.target?.value);
  };


  const handelEfect = (event) => {
    setefecto(event?.target?.value);
  };


  const handelImportOnBurl = (event) => {
    setImporte(Number(importe).toFixed(2));
  };


  const handleSubmit = async() => {
    
    var data = {
      detail: {
        id_account: id_concept,
        amount: Number(importe),
        efect: efecto
      }
    };

    setOpen(true);    
      try {
        if(props.method==="CREATE"){
          await create_expense_detail(props?.row?.id,data)
          setOpen(false);
          dialog.close(true);
        }else{
          await update_expense_detail(props?.row?.id,data,props.id_detail)
          setOpen(false);
          dialog.close(true);
        }
      } catch (err) {
        setOpen(false);
        alert("Error:" + JSON.stringify(err?.response?.data?.errorMessage));
      }
      
  };


  const handleprod_concept_list = async(event) => {
    try {
      var dt= await get_accounting_accounts(props.foreign);
      setpro_servData(dt?.data);
    } catch (error) {
     
    }
    

  };

  useEffect(() => {
    handleprod_concept_list();
  }, []);

  return (
    <div>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <div className="centermodaldescarga">
              <Typography
                id="modal-modal-title"
                className=""
                variant="h6"
                component="h2"
              >
                <img alt="" src={Spinner}></img>
              </Typography>
              <Typography
                id="modal-modal-description"
                className=""
                sx={{ mt: 2 }}
              >
                {"Creando detalle..."}
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>
      <div>
        <form>
          <Box>


          <div style={{ width: "90%", margin:"0 auto"}}>
                  <ReactSearchAutocomplete
                    items={pro_servData}
                    fuseOptions={{ keys: ["id","name_code"]}}
                    resultStringKeyName={"name_code"}
                    onSearch={handleOnSearch}
                    onHover={handleOnHover}
                    onSelect={handleOnSelect}
                    onFocus={handleOnFocus}
                    onClear={handleOnClear}
                    showIcon={true}
                    maxResults={30}
                    placeholder="Buscar concepto..."
                    styling={{
                      height: "34px",
                      border: "1px solid darkgreen",
                      borderRadius: "4px",
                      backgroundColor: "white",
                      boxShadow: "none",
                      hoverBackgroundColor: "lightgreen",
                      color: "black",
                      iconColor: "black",
                      lineColor: "black",
                      placeholderColor: "black",
                      clearIconMargin: "3px 8px 0 0",
                      zIndex: 10,
                    }}
                  />
                </div>

           
          </Box>

        
            <Box className="expen-7">
            <ValidationTextField
                label="Concepto"
                variant="outlined"
                placeholder="Cuenta"
                sx={{ m: 2, width: "50%" }}
                value={concept_data}
                InputLabelProps={{ shrink: true }}
              />
              <ValidationTextField
                label="Cuenta"
                variant="outlined"
                placeholder="Cuenta"
                sx={{ m: 2, width: "34%" }}
                value={concept_code}
                InputLabelProps={{ shrink: true }}
              />
             
            
            </Box>

            <Box>
            

            <TextField
              select
              label="Efecto"
              value={efecto}
              sx={{ m: 1.5, width: "44%" }}
              onChange={handelEfect}
              size="small"
              name="taxes"
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                native: true,
              }}
            >
              {EFECTO.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
              <ValidationTextField
                label="Importe"
                variant="outlined"
                placeholder="Importe"
                type="number"
                sx={{ m: 2, width: "40%" }}
                onChange={handelImporte}
                onBlur={handelImportOnBurl}
                value={importe}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
       
          <Box>
            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal"
              onClick={()=>handleSubmit()}
            >
              Crear
            </Button>

            <Button
              variant="contained"
              sx={{ m: 1.5, width: "20ch" }}
              className="ButtonModal2"
              onClick={() => {
                dialog.close();
              }}
            >
              cancelar
            </Button>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default CREATE_UPDATE;
