//PAQUETERIAS
import React, { useEffect, useState, useMemo, useRef } from "react";
import NotificationSystem from "react-notification-system";
//COMPONENTES
import { SelectColumnFilter } from "../componentsCFDI/filters";
import TableContainer from "./TableContainerBusnes";
import SubTable from "./subTable";

import Modal_Table from "../main_components/modal/modal_Table";
//ESTILOS
import "../componentsCFDI/estilo.css";
import "./tableclientes.css";
import "../componentsCFDI/modal.css";
//IMAGENES/ICONOS

import DemoJson3 from "../dataComponets/demo3.json";
import { Style } from "@material-ui/icons";
import AWS from 'aws-sdk';

var style = {
  NotificationItem: {
    // Override the notification item
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      margin: "10px 5px 2px 1px",
    },

    success: {
      // Applied only to the success notification item
      color: "red",
    },
  },
};

const s3 = new AWS.S3({
  accessKeyId: 'AKIA5W5TQU2MHPTYHK6J',
  secretAccessKey: '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',
  region: 'us-east-1'
});

const BlemSales = () => {

  const [mensajeDescarga, setmensajeDescarga] = useState("");
  console.log(DemoJson3);

  const [data, setData] = useState([]);
  const [cancel, setCancel] = useState(null);
  const [isLoding, setIsLoding] = useState(true);
  const [menssage, setmenssage] = useState("Consultando socios de negocios...");
  const [modalGeneral, setmodalGeneral] = useState(true);
  const [modalT, setmodalT] = useState(true);
  const notificationSystemRef = useRef();
  const [menssageAler, setmenssageAler] = useState("");

  const handleSubmit = (msjAler) => {
    setmodalT(true);
    setData(DemoJson3);
    setIsLoding(true);
    setmodalT(false);

    setmodalGeneral(false);
  };

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleReconsulta = (reconsultar, msjAler) => {
    if (reconsultar === true) {
      handleSubmit(msjAler);
    } else {
      console.log("no se reconsulto");
    }
  };




  
  
  
  


  function handleArchivoSeleccionado(evento) {
    const archivoExcel = evento.target.files[0];
    console.log(archivoExcel)
  }
  const columns = useMemo(
    () => [
      // {
      //   id: "expander",
      //   Header: "Acciones",
      //   Cell: ({ row }) => {
      //     function guardardato() {
      //       sessionStorage.setItem("socioHabilitado", row.original.id);
      //     }

      //     return (
      //       <a {...row.getToggleRowExpandedProps()}>
      //         {row?.isExpanded ? (
      //           <span>🔼</span>
      //         ) : (
      //           <span onClick={guardardato}>🔽</span>
      //         )}
      //       </a>
      //     );
      //   },
      // },
    

      {
                Header: "Warehouse",
                accessor: "warehouse",
                Filter: SelectColumnFilter,
      },

{
  Header: "Breakdown of Projects by Closing Chance",
  accessor: (d)=>{
    return(<div>{d.Breakdown}</div>)
  },
  Filter: SelectColumnFilter,
},
     
      {
        Header: "Annual Sales",
        columns: [
          {
            Header: "2023",
            id:"anualsales2023",
            columns: [
              
              {
                Header: "Sales",
                accessor: (d) => {
                  return <div>{d?.annual_sales.year_2023.sales}</div>;
                },
              },
             
              // otras columnas aquí, si las hay
            ],
          },
          {
            Header: "2024",
            columns: [
              
             
              {
                Header: "Sales ",
                accessor: (d) => {
                  return <div>{d?.annual_sales.year_2024.sales}</div>;
                },
              },
            
              // otras columnas aquí, si las hay
            ],
          },
          {
            Header: "2025",
            columns: [
              
              {
                Header: " Sales ",
                accessor: (d) => {
                  return <div>{d?.annual_sales.year_2025.sales}</div>;
                },
              },
             
              // otras columnas aquí, si las hay
            ],
          },
        ],
      },

      {
        Header: "Blended Sales",
        columns: [
          {
            Header: "2023",
            columns: [
          
              {
                Header: " Sales",
                accessor: (d) => {
                  return <div>{d?.blended_sales.year_2023.sales}</div>;
                },
              },
              {
                Header: " %",
                accessor: (d) => {
                  return (
                    <div>{d?.blended_sales.year_2023.sales_porcentaje}</div>
                  );
                },
              },
              // otras columnas aquí, si las hay
            ],
          },
          {
            Header: "2024",
            columns: [
          
              {
                Header: " Sales  ",
                accessor: (d) => {
                  return <div>{d?.blended_sales.year_2024.sales}</div>;
                },
              },
              {
                Header: " %  ",
                accessor: (d) => {
                  return (
                    <div>{d?.blended_sales.year_2024.sales_porcentaje}</div>
                  );
                },
              },
              // otras columnas aquí, si las hay
            ],
          },
          {
            Header: "2025",
            columns: [
          
              {
                Header: "  Sales  ",
                accessor: (d) => {
                  return <div>{d?.blended_sales.year_2025.sales}</div>;
                },
              },
              {
                Header: "  %  ",
                accessor: (d) => {
                  return (
                    <div>{d?.blended_sales.year_2025.sales_porcentaje}</div>
                  );
                },
              },
              // otras columnas aquí, si las hay
            ],
          },
        ],
      },


     
      


    ],
    []
  );


  const dtafilter=data.filter((a)=>a.warehouse==="FAT").filter((e)=>e.closing_chance==="100%");

  const sumsales = dtafilter
  .filter((sale) => !isNaN(parseFloat(sale.annual_sales.year_2023.sales)))
  .map((sale) => parseFloat(sale.annual_sales.year_2023.sales))
  .reduce((previous, current) => previous + current, 0);

  console.log(sumsales);





  return (
    <div>
      <NotificationSystem ref={notificationSystemRef}></NotificationSystem>
      <Modal_Table
        open={true}
        message={menssage}
        modalGeneral={modalGeneral}
        modalT={modalT}
      ></Modal_Table>
      <div className="">
        <div className="">

        <div>
    <input type="file" onChange={handleArchivoSeleccionado} />
  </div>        
          <div className="TableConsulDemo">
            <TableContainer
              exportEx={true}
              columns={columns}
              data={data}
              anualSale={true}
              Gmodal={modalT}
              style={{ overflowx: 10 }}
              renderRowSubComponent={(row) => {
                return (
                  <div style={{ padding: "20px" }}>
                    <SubTable />
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlemSales;
