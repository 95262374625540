import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
//Date Picker Imports

import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

const SEARCH_FILTERS = (props) => {
  return (
    <Box className="controlInvoice" sx={{ display: 'flex', gap: '5px', p: '2px', zIndex: "10", alignItems: 'flex-start' }}>
      <FormControl required sx={{ width: 100 }}>
        <InputLabel id="demo-simple-select-required-label">Estado</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={props.status}
          label="Estado *"
          sx={{ height: '35px' }}
          onChange={(e) => props.setstatus(e.target.value)}
        >
          <MenuItem value={true}>HABILITADO</MenuItem>
          <MenuItem value={false}>DESHABILITADO</MenuItem>
        </Select>
      </FormControl>
      
    
      <FormControl required sx={{ width: 100 }}>
        <Button variant="contained"
          sx={{ height: '35px' }}
          onClick={(e) => props.handle_business_partner()}
        >
          CONSULTAR
        </Button>
      </FormControl>
    </Box>
  );
};

export default SEARCH_FILTERS;
