//PAQUETERIAS
import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupSharpIcon from "@mui/icons-material/GroupSharp";
import MonetizationOnSharpIcon from "@mui/icons-material/MonetizationOnSharp";
import IsoSharpIcon from "@mui/icons-material/IsoSharp";
import ProductionQuantityLimitsSharpIcon from "@mui/icons-material/ProductionQuantityLimitsSharp";
import FlagIcon from '@mui/icons-material/Flag';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import HailIcon from '@mui/icons-material/Hail';
import PercentIcon from '@mui/icons-material/Percent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import FactoryIcon from '@mui/icons-material/Factory';
import BadgeIcon from '@mui/icons-material/Badge';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import TerminalIcon from '@mui/icons-material/Terminal';
//COMPONENTES

import TableCatalogs from "./tableGeneral";
//ESTILOS
import "./general_catalogs.css";
import { CustomDialog, useDialog, Confirm, Alert } from "react-st-modal";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const General = () => {
  const [value, setValue] = React.useState(0);
  const [clients, setclients] = React.useState("");
  const [detailSale, setdetailSale] = React.useState("none");
  const [bonus, setbonus] = React.useState("none");
  const [product, setproduct] = React.useState("none");
  const [noSale, setnoSale] = React.useState("none");
  const [data, setData] = React.useState([]);


  return (
    <div className="">
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <div className="containerGeneralCatalogsResponse">
          <div className="ctl-1 ">
            <BottomNavigationAction
              label="icono icono icono ico"
              icon={<FlagIcon/>
              }
              onClick={async () => {
                const result = await CustomDialog(
                  <TableCatalogs response={"type"} tablename={"Type"}></TableCatalogs>,
                  {
                    className: "modalSetBussnes",
                    title: "Type",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}
            />
            <p>Type</p>
          </div>

          <div className="ctl-2 ">
            <BottomNavigationAction
              label="icono icono icono ico"
              icon={<MonetizationOnSharpIcon/>}
              onClick={async () => {
                const result = await CustomDialog(
                  <TableCatalogs response={"bom"} tablename={"BOM"}></TableCatalogs>,
                  {
                    className: "modalSetBussnes",
                    title: "BOM",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}
            />
            <p>BOM</p>
          </div>
          <div className="ctl-3 ">
            <BottomNavigationAction
              label="icono icono icono ico"
              icon={<CorporateFareIcon/>}
              onClick={async () => {
                const result = await CustomDialog(
                  <TableCatalogs response={"sector"} tablename={"Sector"}></TableCatalogs>,
                  {
                    className: "modalSetBussnes",
                    title: "Sector",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}
            />
            <p>Sector</p>
          </div>
          <div className="ctl-4 ">
            <BottomNavigationAction
              label="icono icono icono ico"
              icon={<HailIcon/>}
              onClick={async () => {
                const result = await CustomDialog(
                  <TableCatalogs response={"agent"} response2={"id_user"} tablename={"Agent"} agentdata={true}></TableCatalogs>,
                  {
                    className: "modalSetBussnes",
                    title: "Agent",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}
            />
            <p>Agent</p>
          </div>
          <div className="ctl-5 ">
            <BottomNavigationAction
              label="icono icono icono ico"
              icon={<LocalShippingIcon />}
              onClick={async () => {
                 // 'Source': ['source', 'country', 'i_factor'],
                const result = await CustomDialog(
                  <TableCatalogs response={"source"} response2={"country"} response3={"i_factor"} tablename={"Source"} columdata={3}  visibilitydate={2}></TableCatalogs>,
                  {
                    className: "modalSetBussnes",
                    title: "Source",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}
            />
            <p>Source</p>
          </div>

          <div className="ctl-6 ">
            <BottomNavigationAction
              label="icono icono icono ico"
              icon={<PercentIcon />}
              onClick={async () => {
                const result = await CustomDialog(
                  <TableCatalogs response={"closing_chances"} response2={"description"} tablename={"Closing_Chances"} columdata={2}  visibilitydate={2}></TableCatalogs>,
                  {
                    className: "modalClosingChances",
                    title: "Closing Chances",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}
            />
            <p>Closing Chances</p>
          </div>
          <div className="ctl-7 ">
            <BottomNavigationAction
              label="icono icono icono ico"
              icon={<AttachMoneyIcon/>}
              onClick={async () => {
                const result = await CustomDialog(
                  <TableCatalogs response={"exr"} tablename={"EXR"} ></TableCatalogs>,
                  {
                    className: "modalSetBussnes",
                    title: "EXR",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}
            />

            <p>EXR</p>
          </div>
          <div className="ctl-8 ">
            <BottomNavigationAction
              label="icono icono icono ico"
              icon={
                <HomeRepairServiceIcon  />
              }
              onClick={async () => {
                const result = await CustomDialog(
                  <TableCatalogs response={"mpg"} tablename={"MPG"} ></TableCatalogs>,
                  {
                    className: "modalSetBussnes",
                    title: "MPG",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}
            />

            <p>MPG</p>
          </div>

          <div className="ctl-9 ">
            <BottomNavigationAction
              label="icono icono icono ico"
              icon={<PrecisionManufacturingIcon  />}
              onClick={async () => {
                const result = await CustomDialog(
                  <TableCatalogs response={"production_brand"} tablename={"Production_Brand"} ></TableCatalogs>,
                  {
                    className: "modalSetBussnes",
                    title: "Production Brand",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}            />
            <p>Production Brand</p>
          </div>

          <div className="ctl-10 ">
            <BottomNavigationAction
              label="icono icono icono ico"
              icon={<BadgeIcon />}
              onClick={async () => {
                const result = await CustomDialog(
                  <TableCatalogs response={"production_nameplate"} tablename={"Production_Nameplate"} ></TableCatalogs>,
                  {
                    className: "modalSetBussnes",
                    title: "Production Nameplate",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}/>
            <p>Production Nameplate</p>
          </div>
          <div className="ctl-11">
            <BottomNavigationAction
              label="icono icono icono ico"
              icon={<FactoryIcon />}
              onClick={async () => {
                const result = await CustomDialog(
                  <TableCatalogs response={"production_plant"} tablename={"Production_Plant"} ></TableCatalogs>,
                  {
                    className: "modalSetBussnes",
                    title: "Production Plant",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}/>
            
            <p>Production Plant</p>
          </div>
          <div className="ctl-12">
            <BottomNavigationAction
              label="icono icono icono ico"
              icon={
                <TerminalIcon />
              }
              onClick={async () => {
                const result = await CustomDialog(
                  <TableCatalogs response={"program"} tablename={"Program"} ></TableCatalogs>,
                  {
                    className: "modalSetBussnes",
                    title: "Program",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}/>
            <p>Program</p>
          </div>

          <div className="ctl-13 ">
            <BottomNavigationAction
              label="icono icono icono ico"
              icon={<IntegrationInstructionsIcon />}
              onClick={async () => {
                const result = await CustomDialog(
                  <TableCatalogs response={"platform"} tablename={"Platform"} ></TableCatalogs>,
                  {
                    className: "modalSetBussnes",
                    title: "Platform",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}/>
            <p>Platform</p>
          </div>

          <div className="ctl-14">
            <BottomNavigationAction
              label="icono icono icono ico"
              icon={<LocationOnIcon/>}
              onClick={async () => {
                const result = await CustomDialog(
                  <TableCatalogs response={"country"} tablename={"Country"} ></TableCatalogs>,
                  {
                    className: "modalSetBussnes",
                    title: "Country",
                    showCloseIcon: true,
                    isCanClose: false,
                  }
                );
              }}/>
            <p>Country</p>
          </div>
        </div>
      </BottomNavigation>
    </div>
  );
};


  // 'Platform': ['platform'],
  
  // 'Country': ['country', 'code']

export default General;
